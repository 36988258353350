import React from "react"
import styled from "styled-components"
import { Section, Container } from "../global"

const Features = () => (
  <Section id="features">
    <StyledContainer>
      {/* <Subtitle>Features</Subtitle> */}
      <SectionTitle>Features</SectionTitle>
      <FeaturesGrid>
        <FeatureItem>
          <FeatureTitle>Set Levels and Expectations</FeatureTitle>
          <FeatureText>
            The foundation module lets you define the primary responsibilities and expectations for every engineer in your group.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Know How Your Team is Doing</FeatureTitle>
          <FeatureText>
            Team pulse module lets you get real-time insight about how your team members are doing through 1-minute weekly pulse surveys.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Get the most out of your 1:1s</FeatureTitle>
          <FeatureText>
            The one-on-one module gives you a set of tools to help you organize and execute effective 1:1s. It automatically creates a 1:1 agenda with important topics and action items for you.
          </FeatureText>
        </FeatureItem>
        <FeatureItem>
          <FeatureTitle>Grow Your Team Members</FeatureTitle>
          <FeatureText>
            Instead of running an annual, bi-annual, or quarterly review process which is heavy on everyone involved and ineffective, the ManagersApp performance module allows you to continuously evaluate performance and provide actionable feedback to your team members.
          </FeatureText>
        </FeatureItem>
      </FeaturesGrid>
    </StyledContainer>
  </Section>
)

export default Features

const StyledContainer = styled(Container)``

const SectionTitle = styled.h3`
  color: ${props => props.theme.color.primary};
  display: flex;
  justify-content: center;
  margin: 0 auto 40px;
  text-align: center;
`

// const Subtitle = styled.h5`
//   font-size: 16px;
//   color: ${props => props.theme.color.accent};
//   letter-spacing: 0px;
//   margin-bottom: 12px;
//   text-align: center;
// `

const FeaturesGrid = styled.div`
  // max-width: 670px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  margin: 0px auto;
  grid-column-gap: 40px;
  grid-row-gap: 35px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    grid-template-columns: 1fr;
    padding: 0 64px;
  }
`

const FeatureItem = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`

const FeatureTitle = styled.h4`
  color: ${props => props.theme.color.primary};
  letter-spacing: 0px;
  line-height: 30px;
  margin-bottom: 10px;
  text-align: center;
`

const FeatureText = styled.p`
  text-align: center;
`
