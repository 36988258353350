import React, { useState } from 'react';
import styled from "styled-components"
import addToMailchimp from 'gatsby-plugin-mailchimp'
import { Container, Section } from "../global"
// import ReactGA from 'react-ga';

// ReactGA.initialize(
//   process.env.REACT_APP_GA_TRACKING, {
//   'cookieDomain': 'auto',
// });

const GetStarted = () => {

  const [email, setEmail] = useState('');

  const handleSubmit = event => {
    event.preventDefault();
    // ReactGA.event({
    //   category: 'Prospect',
    //   action: `submitted their email address`
    // });
    window.gtag('event', 'conversion', {
      'send_to': '628381526/HIVHCIqq0uYBENau0asC'
    });
    addToMailchimp(email)
      .then(data => {
        window.location = process.env.REACT_APP_BASE_URL;
      })
      .catch(() => {
        window.location = process.env.REACT_APP_BASE_URL;
      })
  }

  const handleEmailChange = (e) => {
    setEmail(e.currentTarget.value);
  };

  return (
    <StyledSection>
      <Container>
        <Flex>
      <GetStartedContainer>
        <GetStartedTitle>Great Managers Are Made, Not Born</GetStartedTitle>
        <HeaderForm onSubmit={handleSubmit}>
          <HeaderInput
            placeholder="Email"
            onChange={handleEmailChange}
            type="email"
            name="EMAIL"
            required
            id="mce-EMAIL" />
          <HeaderButton>Learn More</HeaderButton>
        </HeaderForm>
        {/* <Subtitle style={{ textAlign: 'center' }}>No credit card required.</Subtitle> */}
      </GetStartedContainer>
      </Flex>
    </Container>
  </StyledSection>
)};

export default GetStarted

const StyledSection = styled(Section)`
  background-color: ${props => props.theme.color.background.light};
  clip-path: polygon(0 0, 100% 14%, 100% 100%, 0% 100%);
`

const GetStartedContainer = styled(Container)`
  display: flex;
  justify-content: center;
  // align-items: center;
  flex-direction: column;
  padding: 80px 0 40px;
  width: 100%;
`

const GetStartedTitle = styled.h3`
  margin: 0 auto 32px;
  text-align: center;
`

// const TryItButton = styled.button`
//   font-weight: 500;
//   font-size: 14px;
//   color: white;
//   letter-spacing: 1px;
//   height: 60px;
//   display: block;
//   margin-left: 8px;
//   text-transform: uppercase;
//   cursor: pointer;
//   white-space: nowrap;
//   background: ${props => props.theme.color.secondary};
//   border-radius: 4px;
//   padding: 0px 40px;
//   border-width: 0px;
//   border-style: initial;
//   border-color: initial;
//   border-image: initial;
//   outline: 0px;
//   &:hover {
//     box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
//   }
//   @media (max-width: ${props => props.theme.screen.md}) {
//   }
//   @media (max-width: ${props => props.theme.screen.sm}) {
//     margin-left: 0;
//   }
// `

// const Subtitle = styled.span`
//   ${props => props.theme.font_size.xxsmall}
//   padding-top: 16px;
//   font-size: 14px;
//   color: ${props => props.theme.color.primary};
// `

const HeaderForm = styled.form`
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding-bottom: 16px;
  @media (max-width: ${props => props.theme.screen.sm}) {
    flex-direction: column;
  }
`

const HeaderInput = styled.input`
  font-weight: 500;
  font-size: 16px;
  color: ${props => props.theme.color.primary};
  line-height: 42px;
  width: 40%;
  text-align: left;
  height: 60px;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.color.secondary};
  border-image: initial;
  border-radius: 4px;
  padding: 8px 16px;
  outline: 0px;
  &:focus {
    box-shadow: inset ${props => props.theme.color.secondary} 0px 0px 0px 2px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
    margin-bottom: 8px;
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    display: block;
    width: 100%;
  }
`

const HeaderButton = styled.button`
  font-weight: 500;
  font-size: 14px;
  color: white;
  letter-spacing: 1px;
  height: 60px;
  display: block;
  margin-left: 8px;
  text-transform: uppercase;
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.theme.color.secondary};
  border-radius: 4px;
  padding: 0px 40px;
  border-width: 0px;
  border-style: initial;
  border-color: initial;
  border-image: initial;
  outline: 0px;
  &:hover {
    box-shadow: rgba(110, 120, 152, 0.22) 0px 2px 10px 0px;
  }
  @media (max-width: ${props => props.theme.screen.md}) {
  }
  @media (max-width: ${props => props.theme.screen.sm}) {
    margin-left: 0;
  }
`

const Flex = styled.div`
  display: grid;
  justify-content: space-between;
  align-content: center;
  grid-template-columns: 4fr;
  @media (max-width: ${props => props.theme.screen.md}) {
    grid-template-columns: 1fr;
    grid-gap: 10px;
  }
`